#payment-card-icon-wrapper.payment-card__brand-codensa .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/3a987e70-7506-11ee-8a21-f7cc79dd73f5-l.svg) !important;  } #payment-card-icon-wrapper.payment-card__brand-codensa { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-visa .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/9042e980-7503-11ee-b8a0-4d709b67ff2a-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-visa { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-amex .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/b8532d60-7501-11ee-90cd-f389c3b4dbd4-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-amex { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-master .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/ed5f3c50-7502-11ee-90cd-f389c3b4dbd4-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-master { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-davivienda .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/e254b570-0403-11ef-9eaf-6f9218b4567a-l.svg) !important;  } #payment-card-icon-wrapper.payment-card__brand-davivienda { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-debmaster .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/39a93940-7502-11ee-8a21-f7cc79dd73f5-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-debmaster { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-diners .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/4f4b8a60-774a-11ee-90cd-f389c3b4dbd4-l.svg) !important;  } #payment-card-icon-wrapper.payment-card__brand-diners { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-account_money .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/5ec6cce0-0401-11ef-abe6-ef0735c1c9af-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-account_money { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-pse .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/86c78a20-0430-11ef-9eaf-6f9218b4567a-l.svg) !important;  } #payment-card-icon-wrapper.payment-card__brand-pse { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-debvisa .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/2dba7030-7756-11ee-8a21-f7cc79dd73f5-l.svg) ;  } #payment-card-icon-wrapper.payment-card__brand-debvisa { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-efecty .payment-card-icon { background-image: url(https://http2.mlstatic.com/storage/logos-api-admin/17c72310-c340-11e8-ad6b-47a846c5c248-l.svg) !important;  } #payment-card-icon-wrapper.payment-card__brand-efecty { padding: 8px; border: 1px solid var(--andes-gray-070-solid, #ededed);  }
#payment-card-icon-wrapper.payment-card__brand-visa {
      padding: 0;
    }
    #payment-card-icon-wrapper.payment-card__brand-visa .payment-card-icon {
      max-width: 24px;
    }